const errorMessageDefaults = [
	"Damn! Our amp must have blown or something.",
	"We are as busy as a Harry Styles concert at the moment.",
	"Our AI just stumbled on his guitar cords",
	"This data is currently unavailable",
];

interface ErrorMessageProps {
	errorMessages?: string[];
}
export default function ErrorMessage(props: ErrorMessageProps) {
	const errorMessages = props.errorMessages ?? errorMessageDefaults;
	const index = Math.floor(Math.random() * (errorMessages.length - 0));

	return (
		<div className="txt-col-black" style={{ minHeight: "20px", padding: "20px", lineHeight: 1.5 }}>
			<b>Error:</b>
			<br />
			{errorMessages[index]} <br /> Please try again later
		</div>
	);
}
