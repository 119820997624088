import { useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import axios from "axios";

export default function SpotifyAuth() {
	const firstUpdate = useRef(true);

	// Spotify Redirect
	const [searchParams, setSearchParams] = useSearchParams();

	const updateSpotifyAccessToken = async (code: string) => {
		const isDev = process.env.REACT_APP_IS_DEV ? process.env.REACT_APP_IS_DEV === "true" : false;
		const response = await axios.get(
			`${process.env.REACT_APP_API_BASE_URL}/spotify/publicAuth?code=${code}${isDev ? "&isDev=true" : ""}`
		);
		localStorage.setItem("spotify_access_token", response.data.data.access_token);
		window.open("about:blank", "_self");
		window.close();
	};

	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;

			if (searchParams.get("code")) {
				const updateAccessToken = async () => updateSpotifyAccessToken(searchParams.get("code")!);
				updateAccessToken();
			}
		}
	}, [searchParams]);

	return <></>;
}
