import ErrorMessage from "../ErrorMessage/ErrorMessage";
import LoadingCell from "../LoadingCell/LoadingCell";

interface LoadingWrapperProps {
	loaded: boolean;
	error: any;
	children: any;
	loadingComponent?: any;
	errorComponent?: any;
}

const LoadingWrapper = (props: LoadingWrapperProps) => {
	const { loaded, error, children, loadingComponent, errorComponent } = props;
	const defaultError = errorComponent ? errorComponent : <ErrorMessage />;
	const defaultLoading = loadingComponent ? loadingComponent : <LoadingCell />;

	return loaded ? (error ? defaultError : children) : defaultLoading;
};

export default LoadingWrapper;
