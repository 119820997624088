// Router
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Redux
import { Provider } from "react-redux";
import store from "./redux/predictorStore/store";

// Querying
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Components
import Header from "./components/sections/Header/Header";
import NotFound from "./components/core/NotFound/NotFound";
import ResultsPage from "./components/Pages/ResultsPage/ResultsPage";
import SearchPage from "./components/Pages/SearchPage/SearchPage";
import SetlistPage from "./components/Pages/SetlistPage/SetlistPage";
import BrowseArtists from "./components/Pages/BrowseArtists/BrowseArtists";
import CallbackSpotify from "./components/Pages/CallbackSpotify/CallbackSpotify";

const router = createBrowserRouter([
	{
		path: "/",
		element: <SearchPage />,
		errorElement: <NotFound />,
	},
	{
		path: "/search",
		element: <ResultsPage />,
		errorElement: <NotFound />,
	},
	{
		path: "/setlist-prediction/:artistName/:artistID",
		element: <SetlistPage />,
		errorElement: <NotFound />,
	},
	{
		path: "/browse/artists",
		element: <BrowseArtists />,
		errorElement: <NotFound />,
	},
	{
		path: "/callback/spotify",
		element: <CallbackSpotify />,
		errorElement: <NotFound />,
	},
]);

const queryClient = new QueryClient();

function App() {
	// Allows the back button to work
	window.onpopstate = function () {
		window.location.reload();
	};

	return (
		<div>
			<Header />
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					<RouterProvider router={router} />
				</QueryClientProvider>
			</Provider>
			<div className="noise-bg"></div>
		</div>
	);
}

export default App;
