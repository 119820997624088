import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Redux
import { setSearchTerm } from "../../../../../redux/predictorStore/userInput";

// Components
import "../../../../../styles/inputs.scss";
import { useWindowSize } from "../../../../../helpers";
import { useDispatch, useSelector } from "react-redux";

const altHeadlines = [
	"Prepare for the most likely setlist",
	"Spare yourself the awkwardness of not knowing the words",
	"Arrive prepared to that next gig of yours",
	"99% of live acts are better when you lose your voice",
	"Never again say “I don't know any of their new stuff”",
];

const headline = altHeadlines[Math.floor(Math.random() * altHeadlines.length)];

function SearchInput() {
	const [width, height] = useWindowSize();
	const navigate = useNavigate();
	const searchTerm = useSelector((state: any) => state.input.search ?? "");
	const [allowSearch, setAllowSearch] = useState<boolean>(searchTerm && searchTerm.length > 0);

	const dispatch = useDispatch();

	const onChange = (e: any) => {
		const newSearchTerm = e.target.value;
		dispatch(setSearchTerm(newSearchTerm));
		setAllowSearch(newSearchTerm && newSearchTerm.length);
	};

	const onSubmit = (e: any) => {
		e.preventDefault();

		if (searchTerm && searchTerm.length > 0) {
			setAllowSearch(true);
			navigate(`/search?q=${searchTerm}`);
		}
	};

	return (
		<div>
			<h1 id="landing-header">
				Never Miss A Beat <i>Again</i>
			</h1>
			<h2 className="txt-col-muted txt-weight-regular margin-top-0 txt-size-regular">{headline}</h2>
			<div id="multi-box" className="transition">
				<form id="search-input-form" className="txt-left" onSubmit={onSubmit} autoComplete={"off"}>
					<input
						id="search-input"
						placeholder="Search for a band, artist, or any other music act"
						onChange={onChange}
						value={searchTerm}
					></input>
					<button
						type="submit"
						id="search-input-submit"
						className={`transition full-opacity`}
						disabled={!allowSearch}
					>
						<div id="search-input-submit-inner" className="transition txt-center">
							{width > 600 ? <>Search</> : <>Go</>}
						</div>
					</button>
				</form>
			</div>
			<h3 className="txt-col-primary txt-size-regular txt-weight-regular">
				Enter an artist of your choice to view their predicted setlist using the latest data available and AI
			</h3>
		</div>
	);
}

export default SearchInput;
