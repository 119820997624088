import { useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

// Redux
import { setSearchTerm, setSelectedArtist } from "../../../../../redux/predictorStore/userInput";

// Components
import { Artist } from "../../../../../types/interfaces/Artist";
import ArtistCell from "../../../../core/ArtistCell/ArtistCell";
import { getQueryParam, URLifyArtistName } from "../../../../../helpers";
import APICallWrapper from "../../../../core/APICallWrapper/APICallWrapper";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

function ResultsList() {
	const [searchTerm, setLocalSearchTerm] = useState<string>(useSelector((state: any) => state.input.search));

	const navigate = useNavigate();

	let queryParamSearch = getQueryParam("q");
	const dispatch = useDispatch();

	const backButton = () => {
		navigate("/");
	};

	// When a user selects an artist, move them to the results page
	const onArtistSelect = (artist: Artist) => {
		dispatch(setSelectedArtist(artist));
		navigate(`/setlist-prediction/${URLifyArtistName(artist.name)}/${artist.id}`);
	};

	useLayoutEffect(() => {
		// If query param exists, set it as the search term, otherwise, go back to search
		if (queryParamSearch && queryParamSearch.length > 0) {
			setLocalSearchTerm(queryParamSearch);
			dispatch(setSearchTerm(queryParamSearch));
		} else {
			backButton();
		}
	});

	const endpoint = { url: "search", method: "get", params: { q: queryParamSearch } };

	return (
		<>
			<Helmet>
				<title>Search results for ‘{searchTerm}’ - Setlist Predictor</title>
				<meta name="description" content={`Find the average setlist for ‘${searchTerm}’`} />
				<meta property="og:title" content={`Search results for ‘${searchTerm}’ - Setlist Predictor`} />
			</Helmet>

			<div>
				{searchTerm && (
					<h1 className="margin-top-0" style={{ fontSize: "1.5em", marginBottom: "0.5rem" }}>
						Search Results For ‘{searchTerm}’
					</h1>
				)}
				<h2 className="txt-col-muted txt-weight-regular margin-top-0 txt-size-regular">
					Select an artist below
				</h2>
				<div id="multi-box" className="transition">
					<div id="results-list-container">
						<APICallWrapper endpoint={endpoint}>
							{({ data }: any) => {
								return (
									<div className="results-list-artists">
										{data.map((artist: Artist) => (
											<ArtistCell
												key={artist.id}
												artist={artist}
												onClick={() => onArtistSelect(artist)}
											/>
										))}
									</div>
								);
							}}
						</APICallWrapper>
					</div>
				</div>
				<div className="txt-center back-to-search">
					<div onClick={() => backButton()} className="txt-decoration-none pointer">
						<p className="m-0 txt-col-primary">Back to search</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default ResultsList;
