import { useState, useEffect } from "react";
import WaveformSpinner from "../WaveformSpinner/WaveformSpinner";

const timeoutLength = 6000;

function LoadingCell(props: { loadingTerm?: string[] }) {
	const { loadingTerm } = props;
	const [messageIndex, setMessageIndex] = useState(0);

	useEffect(() => {
		// Move on to the next message every `n` milliseconds
		if (loadingTerm && loadingTerm.length > 1) {
			let timeout: any;
			if (messageIndex < loadingTerm.length - 1) {
				timeout = setTimeout(() => setMessageIndex(messageIndex + 1), timeoutLength);
			} else {
				timeout = setTimeout(() => setMessageIndex(0), timeoutLength);
			}

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [loadingTerm, messageIndex]);

	return (
		<div id={"loading-container"}>
			<WaveformSpinner hide={false} />
			<div className={"txt-col-muted margin-top-0-5 txt-center"}>
				{loadingTerm ? loadingTerm[messageIndex] : "Loading"}
			</div>
		</div>
	);
}

export default LoadingCell;
