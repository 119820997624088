// Components
import MadeByKane from "../../core/MadeByKane/MadeByKane";
import TopSectionWrapper from "../../sections/TopSectionWrapper/TopSectionWrapper";
import SearchInput from "./sub-components/SearchInput/SearchInput";

function SearchTopSection() {
	return (
		<>
			<TopSectionWrapper>
				<SearchInput />
				<div style={{ position: "absolute", bottom: "20px", maxWidth: "1200px", width: "100%" }}>
					<MadeByKane />
				</div>
			</TopSectionWrapper>
		</>
	);
}

export default SearchTopSection;
