import { Artist } from "../../../types/interfaces/Artist";

interface ArtistCellProps {
	artist: Artist;
	hideText?: boolean;
	onClick?(): any;
	minHeight?: number;
}

function ArtistCell(props: ArtistCellProps) {
	const { artist, onClick, minHeight } = props;

	const minHeightStyle = minHeight ? { minHeight } : {};
	return (
		<div
			key={`artist-${artist.id}`}
			className="artist-cell"
			style={{
				backgroundImage: `url(${
					artist.image ?? "https://setlist-predictor.s3.ap-southeast-2.amazonaws.com/artist-images/back.png"
				})`,
				cursor: onClick ? "pointer" : "default",
				...minHeightStyle,
			}}
			onClick={onClick ? onClick : () => null}
		>
			{!props.hideText && <div className="artist-cell-header">{artist.name}</div>}
		</div>
	);
}

export default ArtistCell;
