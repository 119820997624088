// Components
import TopSectionWrapper from "../../sections/TopSectionWrapper/TopSectionWrapper";
import ResultsList from "./sub-components/ResultsList/ResultsList";

function ResultsTopSection() {
	return (
		<TopSectionWrapper>
			<ResultsList />
		</TopSectionWrapper>
	);
}

export default ResultsTopSection;
