import logo from "./logo.png";
import "./header.scss";

function Header() {
	return (
		<a href="/" style={{ textDecoration: "none", color: "white" }}>
			<nav className="menu-container">
				<div className="inner-header-container">
					<div className="menu-logo">
						<img src={logo} alt="Setlist Predictor Logo" />
					</div>
					<div className="menu">Setlist Predictor</div>
				</div>
			</nav>
		</a>
	);
}

export default Header;
