// Components
import MadeByKane from "../../core/MadeByKane/MadeByKane";
import TopSectionWrapper from "../../sections/TopSectionWrapper/TopSectionWrapper";
import SetlistView from "./sub-components/Controller/Steps/SetlistView/SetlistView";

function SetlistTopSection() {
	return (
		<TopSectionWrapper>
			<SetlistView />
			<div style={{ marginTop: "40px" }}>
				<MadeByKane />
			</div>
		</TopSectionWrapper>
	);
}

export default SetlistTopSection;
