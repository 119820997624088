import { useState, useEffect, useRef } from "react";
import { Song } from "../../../types/interfaces/Song";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faPause, faPlay } from "@fortawesome/free-solid-svg-icons";

import spotify_black from "../../../img/spotify_icon_white.png";
import styles from "./index.module.scss";
import { Artist } from "../../../types/interfaces/Artist";

interface AudioControllerProps {
	songs: Song[];
	artist: Artist;
	setPlayingIndex: any;
	paused: boolean;
	setPaused: any;
	songIndex: number;
}

function str_pad_left(string: string, pad: string, length: number) {
	return (new Array(length + 1).join(pad) + string).slice(-length);
}

const durationToMinutes = (duration: number) => {
	const minutes = Math.floor(duration / 60);
	const seconds = duration.toFixed(0);
	return str_pad_left(`${minutes}`, "0", 2) + ":" + str_pad_left(`${seconds}`, "0", 2);
};

export default function AudioController(props: AudioControllerProps) {
	const { artist, paused, songs, songIndex } = props;

	// State
	const [trackIndex, setTrackIndex] = useState(songIndex);
	const [trackProgress, setTrackProgress] = useState(0);
	const [isPlaying, setIsPlaying] = useState(false);

	const song = songs[trackIndex];
	const { spotify_preview_url } = song;

	// Refs
	const audioRef = useRef(new Audio(spotify_preview_url!));
	const isReady = useRef(false);
	const intervalRef = useRef<any>(null);

	// Destructure for conciseness
	const { duration } = audioRef.current;

	const startTimer = () => {
		// Clear any timers already running
		clearInterval(intervalRef.current);

		intervalRef.current = setInterval(() => {
			if (audioRef.current.ended) {
				toNextTrack();
			} else {
				setTrackProgress(audioRef.current.currentTime);
			}
		}, 1000);
	};

	const toNextTrack = () => {
		let newIndex = 0;

		for (let i = trackIndex + 1; i < songs.length - 1; i++) {
			if (songs[i].spotify_preview_url) {
				newIndex = i;
				break;
			}
		}

		setTrackIndex(newIndex);
		props.setPlayingIndex(newIndex);
	};

	const pauseMusic = () => {
		audioRef.current.pause();
		setIsPlaying(false);
		props.setPaused(true);
	};

	const continueMusic = () => {
		audioRef.current.play();
		setIsPlaying(true);
		startTimer();
		props.setPaused(false);
	};

	useEffect(() => {
		if (isPlaying) {
			audioRef.current.play();
			startTimer();
		} else {
			audioRef.current.pause();
		}
	}, [isPlaying]);

	// Handles cleanup and setup when changing tracks
	useEffect(() => {
		audioRef.current.pause();
		audioRef.current = new Audio(spotify_preview_url!);
		setTrackProgress(audioRef.current.currentTime);

		if (isReady.current) {
			continueMusic();
		} else {
			isReady.current = true;
		}
	}, [trackIndex]);

	useEffect(() => {
		return () => {
			audioRef.current.pause();
			clearInterval(intervalRef!.current);
		};
	}, []);

	useEffect(() => {
		if (paused) {
			pauseMusic();
		} else {
			continueMusic();
		}
	}, [paused]);

	useEffect(() => {
		setTrackIndex(songIndex);
	}, [songIndex]);

	return (
		<div className={styles["audio-controller"]}>
			<div className={styles["column"]}>
				<a
					href={`https://open.spotify.com/track/${song.spotify_id}`}
					target="_blank"
					className="txt-transform-none"
				>
					{song.image && <img src={song.image} />}
				</a>
			</div>
			<div
				className={`${styles["column"]} ${styles["right-content"]} ${styles["opp-ends"]}`}
				style={{ width: "100%", paddingLeft: "10px" }}
			>
				<div className={styles["song-container"]}>
					<div className={styles["song-name"]}>{song.name}</div>
					<div className={styles["song-album"]}>
						<i>{song.album ? `${song.album}` : null}, </i> {artist.name}
					</div>
				</div>
				<div className="w-100">
					<div className={`flex-row ${styles["opp-ends"]}`} style={{ marginBottom: "4px" }}>
						<div className={styles["preview-text"]} style={{ flex: "0 1 auto" }}>
							Preview
						</div>
						<div className={styles["timer"]} style={{ flex: "0 1 auto" }}>
							{isPlaying ? (
								<FontAwesomeIcon
									icon={faPause}
									className={"pointer"}
									onClick={() => pauseMusic()}
									style={{ marginRight: "5px" }}
								/>
							) : (
								<FontAwesomeIcon
									icon={faPlay}
									className={"pointer"}
									onClick={() => continueMusic()}
									style={{ marginRight: "5px" }}
								/>
							)}
							{durationToMinutes(trackProgress)}/
							{duration ? (
								durationToMinutes(duration)
							) : (
								<FontAwesomeIcon
									className={"fa-spin"}
									icon={faCircleNotch}
									style={{ marginLeft: "2px" }}
								/>
							)}
						</div>
					</div>
					<a href={`https://open.spotify.com/track/${song.spotify_id}`} target="_blank">
						<div className={`button-primary txt-col-white ${styles["spotify-link"]}`}>
							<img src={spotify_black} />
							<div className={styles["spotify-link-text"]}>PLAY ON SPOTIFY</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	);
}
