import { ArtistTypes } from "../types/enums/ArtistTypes";
import { Artist } from "../types/interfaces/Artist";
import { ArtistSetlist } from "../types/interfaces/ArtistSetlist";
import {useLayoutEffect, useState} from "react"

export const getQueryParam = (key: string) => {
    const url = new URL(window.location.href);
    return url.searchParams.get(key);
}

export const URLifyArtistName = (name: string) => {
	return name.trim().replace(/\s/g, '-').toLowerCase();
}

export const isArtistSetlist = (artistObject: Artist | ArtistSetlist | null) => {
    if (artistObject && 'id' in artistObject) {
        return ArtistTypes.ARTIST
    } else if (artistObject && 'artist' in artistObject) {
        return ArtistTypes.ARTIST_SETLIST
    }
    return ArtistTypes.NULL
}

export const useWindowSize= () => {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
}