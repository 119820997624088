function WaveformSpinner(props: { hide: boolean }) {
	const { hide } = props;
	return (
		<div id="waveform-spinner" className={hide ? "hide-spinner" : "show-spinner"}>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>
	);
}

export default WaveformSpinner;
