// React
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

// Redux
import { setSelectedArtist } from "../../../../../../../redux/predictorStore/userInput";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";

// Components
// import ArtistCell from "../../../../../../core/ArtistCell/ArtistCell";
import { isArtistSetlist } from "../../../../../../../helpers";

// Types
import { ArtistTypes } from "../../../../../../../types/enums/ArtistTypes";
import { Setlist } from "../../../../../../../types/interfaces/Setlist";
import { Artist } from "../../../../../../../types/interfaces/Artist";
import { Song } from "../../../../../../../types/interfaces/Song";

// Music Player
import MusicPlayer from "../../../../../../core/MusicPlayer/MusicPlayer";
import APICallWrapper from "../../../../../../core/APICallWrapper/APICallWrapper";
import LoadingCell from "../../../../../../core/LoadingCell/LoadingCell";
import { useDispatch, useSelector } from "react-redux";
import SpotifyPlaylistButton from "./SpotifyPlaylistButton";
import Modal from "../../../../../../core/Modal/Modal";

function SetlistView() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [artistData, setArtistData] = useState<Artist | null>(null);
	const [paused, setPaused] = useState<boolean>(false);
	const [selectedArtistSetlist, setSelectedArtistSetlist] = useState<Setlist | null>(null);
	const [songPreviewPlayingIndex, setSongPreviewPlayingIndex] = useState<number>(-1);
	const [first, setFirst] = useState(true);
	const [openSpotifyModal, setOpenSpotifyModal] = useState(false);
	const [hasSpotifyLogin, setHasSpotifyLogin] = useState(false);

	const searchTerm = useSelector((state: any) => state.input.search);
	const artistState = useSelector((state: any) => state.input.selectedArtist);

	useEffect(() => {
		const inputtedArtist: ArtistTypes = isArtistSetlist(artistState);

		if (inputtedArtist === ArtistTypes.ARTIST) {
			setArtistData(artistState);
		}
	});

	useEffect(() => {
		const localStorageSpotifyTokenListen = () => {
			if (localStorage.getItem("spotify_access_token")) setHasSpotifyLogin(true);
		};

		window.addEventListener("storage", localStorageSpotifyTokenListen);
		return () => window.removeEventListener("storage", localStorageSpotifyTokenListen);
	});

	const updateData = (data: any) => {
		setArtistData(data.artist);
		setSelectedArtistSetlist(data.setlistInfo);
		dispatch(setSelectedArtist(artistData));
		setFirst(false);
	};

	const spotifyLogout = () => {
		setOpenSpotifyModal(true);
		setHasSpotifyLogin(false);
		localStorage.removeItem("spotify_access_token");
	};

	const mb_id = window.location.pathname.split("/").splice(-1);
	const endpoint = { url: `/artist/${mb_id}`, method: "get" };

	return (
		<>
			{artistData ? (
				<Helmet>
					<title>{artistData.name} - Setlist Prediction</title>
					{selectedArtistSetlist && (
						<meta
							name="description"
							content={`Prepare for the next ${
								artistData.name
							} concert based off our AI's setlist prediction. We predict ${
								artistData.name
							} will play ${selectedArtistSetlist.setlist
								.slice(0, 3)
								.map((song) => song.name)
								.join(", ")} and ${
								selectedArtistSetlist.setlist.length - 3
							} other songs in their setlist based off setlist data as recent as ${
								selectedArtistSetlist.lastSet
							}`}
						/>
					)}
					<meta property="og:title" content={`${artistData.name} - Average Setlist`} />
					<meta property="og:image" content={artistData.image} />
				</Helmet>
			) : (
				<Helmet>
					<meta
						name="description"
						content="Setlist Predictor helps you prepare for your next concert by providing you with an AI generated setlist prediction for an artist of your choice and an equivalent Spotify playlist so that you never miss a beat again."
					/>
					<title>Setlist Predictor - Never Miss A Beat Again</title>
				</Helmet>
			)}
			<div>
				{artistData && (
					<>
						<h1 className="margin-top-0" style={{ fontSize: "1.5em", marginBottom: "0.5rem" }}>
							Setlist Prediction For {artistData.name}
						</h1>
						{/* <h2 className="txt-col-muted txt-weight-regular margin-top-0 txt-size-regular">
							<a href="/TEST" className="txt-col-primary txt-decoration-none">
								Find Tickets To Shows Near Me
							</a>
						</h2> */}
					</>
				)}
				<div id="multi-box" className="transition">
					<APICallWrapper
						endpoint={endpoint}
						loadingComponent={
							<LoadingCell
								loadingTerm={[
									"Fetching setlists",
									"Generating setlist prediction",
									"Our AI is working hard",
									"Fetching song previews",
									"Not long now",
								]}
							/>
						}
					>
						{({ data }: any) => {
							if (first) {
								updateData(data);
							}

							return (
								<div id="setlist-view-container">
									{/* <div className="results-list-artists">
										<ArtistCell artist={artistData} />
										<div className="artist-cell-no-fill txt-left">
											<div className="margin-bottom-1">
												<p>
													<b>Last Set:</b> {selectedArtistSetlist.lastSet}
												</p>
											</div>
										</div>
									</div> */}
									<div className="txt-left txt-col-black txt-line-height-2 margin-bottom-1">
										<div className="txt-weight-bold">Setlist Prediction:</div>
										{selectedArtistSetlist &&
											selectedArtistSetlist.setlist.map((song: Song, index: number) => (
												<div
													key={song.spotify_id}
													className="w-100 overflow-auto"
													style={{
														minHeight: "32px",
														display: "flex",
														placeContent: "space-between",
														height: "100%",
														width: "100%",
													}}
												>
													<div
														style={{
															order: 0,
															flex: "1 1 auto",
															alignSelf: "center",
															maxWidth: "480px",
															lineHeight: "1.5rem",
														}}
													>
														{index + 1}. {song.name}
													</div>

													<div
														className="float-right"
														style={{ paddingTop: "3px", minWidth: "30px" }}
													>
														{song.spotify_preview_url && (
															<>
																{songPreviewPlayingIndex !== index ||
																(songPreviewPlayingIndex === index && paused) ? (
																	<div
																		className="button-primary txt-col-white txt-center play-icon"
																		onClick={() => {
																			setSongPreviewPlayingIndex(index);
																			setPaused(false);
																		}}
																	>
																		<FontAwesomeIcon icon={faPlay} />
																	</div>
																) : (
																	<div
																		className="button-primary txt-col-primary txt-center play-icon"
																		style={{
																			background: "white",
																			border: "solid 1px #61AAFF",
																		}}
																		onClick={() => {
																			setPaused(true);
																		}}
																	>
																		<FontAwesomeIcon icon={faPause} />
																	</div>
																)}
															</>
														)}
													</div>
												</div>
											))}
										<div className="margin-top-1">
											<SpotifyPlaylistButton
												artist={artistData}
												setlistData={selectedArtistSetlist}
											/>
										</div>
									</div>
								</div>
							);
						}}
					</APICallWrapper>
				</div>

				<div className="txt-center back-to-search margin-bottom-1">
					{hasSpotifyLogin && (
						<p className="margin-bottom-1 txt-col-spotify pointer" onClick={spotifyLogout}>
							Log out of Spotify
						</p>
					)}
					<div
						onClick={() => {
							navigate(`/search?q=${searchTerm ? searchTerm : ""}`);
						}}
						className="txt-decoration-none pointer"
					>
						<p className="m-0 txt-col-primary">Back to search results</p>
					</div>
				</div>
				<div className="txt-left multi-box-max-width" style={{ fontSize: "10px", opacity: 0.5 }}>
					Please note that this the above information is an estimate based on the latest setlist data for the
					artist. Although we are usually correct, we can never predict the setlists of upcoming tours that
					haven't started yet, nor can we predict spontaneous covers or performances.
				</div>
			</div>
			{songPreviewPlayingIndex >= 0 && (
				<MusicPlayer
					artist={artistData!}
					paused={paused}
					songs={selectedArtistSetlist!.setlist}
					songIndex={songPreviewPlayingIndex}
					setPlayingIndex={setSongPreviewPlayingIndex}
					setPaused={setPaused}
				/>
			)}
			{openSpotifyModal && (
				<Modal>
					<div style={{ maxWidth: "400px" }}>
						<div className="margin-bottom-1 txt-weight-bold">We have logged you out of Spotify!</div>
						<div className="margin-bottom-1">
							Setlist Predictor solely possesses the capability to generate/edit playlists within your
							Spotify account. In case you prefer that we discontinue access entirely, you may revoke it
							by following the link{" "}
							<a
								href={"https://www.spotify.com/au/account/apps/"}
								target="_blank"
								className="txt-decoration-none txt-col-primary"
							>
								here
							</a>
							. You will not lose the playlists you have created using Setlist Predictor!
						</div>
						<div
							onClick={() => {
								setOpenSpotifyModal(false);
								document.body.style.overflow = "initial";
							}}
							className="button-primary txt-col-white"
							style={{ margin: "auto", width: "50%", padding: "10px" }}
						>
							Back to setlist
						</div>
					</div>
				</Modal>
			)}
		</>
	);
}

export default SetlistView;
