import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Artist } from "../../types/interfaces/Artist";
import { ArtistSetlist } from "../../types/interfaces/ArtistSetlist";

// Define a type for the slice state
interface UserInput {
  search: string | null;
  selectedArtist: Artist | ArtistSetlist | null; 
}

// Define the initial state using that type
const initialState: UserInput = {
  search: null,
  selectedArtist: null,
}


export const userInputSlice = createSlice({
	name: "userInput",
	initialState,
	reducers: {
		setSearchTerm: (state, action: PayloadAction<string>) => {
			state.search = action.payload
		},
		setSelectedArtist: (state, action: PayloadAction<Artist | ArtistSetlist | null>) => {
			state.selectedArtist = action.payload
		},
	},
});

// Action creators are generated for each case reducer function
export const { setSearchTerm, setSelectedArtist } = userInputSlice.actions;
export default userInputSlice.reducer