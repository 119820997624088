import LoadingWrapper from "../LoadingWrapper/LoadingWrapper";
import { useQueryEndpoint } from "../../../hooks/UseQueryHooks";
import QueryRequestObject from "../../../types/interfaces/QueryRequestObject";

interface APICallWrapperProps {
	endpoint: QueryRequestObject;
	children: any;
	loadingComponent?: any;
}

const APICallWrapper = (props: APICallWrapperProps) => {
	const { endpoint, children, loadingComponent } = props;
	const { data, error, loaded } = useQueryEndpoint(endpoint.url, endpoint.body, endpoint.method, endpoint.params);

	return (
		<LoadingWrapper loaded={loaded} error={error} {...{ loadingComponent: loadingComponent ?? null }}>
			{data ? children({ data: data ?? [] }) : null}
		</LoadingWrapper>
	);
};

export default APICallWrapper;
