// Components
import SetlistTopSection from "./SetlistTopSection";

function SetlistPage() {
	return (
		<>
			<SetlistTopSection />
			{/* <FeaturedSection /> */}
		</>
	);
}

export default SetlistPage;
