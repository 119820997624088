import { useEffect, useState } from "react";
import { Artist } from "../../../../../../../types/interfaces/Artist";
import { Setlist } from "../../../../../../../types/interfaces/Setlist";
import LoadingCell from "../../../../../../core/LoadingCell/LoadingCell";
import APICallWrapper from "../../../../../../core/APICallWrapper/APICallWrapper";
import spotify_icon from "../../../../../../../img/spotify_black.png";
import SpotifyLogo from "../../../../../../../img/spotify_black.png";
import Modal from "../../../../../../core/Modal/Modal";

interface SpotifyPlaylistButtonProps {
	artist: Artist | null;
	setlistData: Setlist | null;
}

export default function SpotifyPlaylistButton(props: SpotifyPlaylistButtonProps) {
	const { artist, setlistData } = props;
	const [accessToken, setAccessToken] = useState<null | string>(null);
	const [open, setOpen] = useState<boolean>(false);

	const loginToSpotify = () => {
		const authEndpoint = "https://accounts.spotify.com/authorize";
		const redirectUri = `${window.location.origin}/callback/spotify`;
		const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
		const scopes = [
			"playlist-read-collaborative",
			"playlist-modify-private",
			"playlist-modify-public",
			"ugc-image-upload",
		];

		return `${authEndpoint}?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scopes.join(
			"%20"
		)}`;
	};

	useEffect(() => {
		const localStorageSpotifyTokenListen = () => {
			if (localStorage.getItem("spotify_access_token")) {
				setAccessToken(localStorage.getItem("spotify_access_token"));
				setOpen(open);
			}
		};

		window.addEventListener("storage", localStorageSpotifyTokenListen);
		return () => window.removeEventListener("storage", localStorageSpotifyTokenListen);
	});

	const endpoint = {
		url: `/spotify/createSetlist`,
		method: "post",
		body: { accessToken: accessToken, artist, setlistData },
	};

	return (
		<>
			<a
				href={loginToSpotify()}
				target="_blank"
				className="txt-decoration-none overflow-auto w-100 h-100"
				onClick={() => setOpen(true)}
			>
				<div className="button-primary txt-col-white txt-center margin-right-1 float-left add-to-playlist-button">
					Add To Playlist{" "}
					<img
						src={spotify_icon}
						width={80}
						style={{ verticalAlign: "bottom", marginLeft: "8px", filter: "invert(1)" }}
					/>
				</div>
			</a>
			{accessToken && open && (
				<Modal>
					<APICallWrapper
						endpoint={endpoint}
						loadingComponent={<LoadingCell loadingTerm={["Generating Spotify Playlist"]} />}
					>
						{({ data }: any) => {
							const { playlistData } = data;
							return (
								<>
									<h3 className="margin-top-0" style={{ lineHeight: 1.5 }}>
										Your new playlist has been
										<br /> added to your Spotify account:
									</h3>
									{playlistData.image && (
										<img
											src={
												playlistData.image.includes("http")
													? playlistData.image
													: `data:image/jpeg;base64,${playlistData.image}`
											}
											className="margin-bottom-1"
											style={{ width: "150px", borderRadius: "8px", margin: "auto" }}
										/>
									)}
									<p className="margin-bottom-1">{playlistData.name}</p>
									<a href={playlistData.link} target="_blank" className="txt-decoration-none">
										<div
											className="button-primary add-to-playlist-button txt-col-white"
											style={{
												width: "calc(100% - 10px)",
												fontSize: "16px",
												lineHeight: "26px",
											}}
										>
											PLAY ON
											<img
												src={SpotifyLogo}
												width={100}
												style={{
													verticalAlign: "bottom",
													marginLeft: "12px",
													filter: "invert(1)",
												}}
											/>
										</div>
									</a>
									<div
										className="txt-col-primary pointer"
										style={{
											width: "150px",
											fontSize: "14px",
											lineHeight: "12px",
											margin: "20px auto 0px",
										}}
										onClick={() => {
											setOpen(false);
											document.body.style.overflow = "initial";
										}}
									>
										Back
									</div>
								</>
							);
						}}
					</APICallWrapper>
				</Modal>
			)}
		</>
	);
}
