export default function MadeByKane() {
	return (
		<div style={{ margin: "auto" }}>
			Made by{" "}
			<a
				target="_blank"
				href="https://twitter.com/kanecomino"
				className="txt-col-primary"
				style={{ textDecoration: "none", fontWeight: "bold" }}
			>
				kane
			</a>
		</div>
	);
}
