import { URLifyArtistName } from "../../../helpers";
import APICallWrapper from "../../core/APICallWrapper/APICallWrapper";
import Panel from "../../core/Panel/Panel";
import logo from "../Header/logo.png";

const endpoint = { url: "/artists/popular" };

export default function PopularArtists() {
	return (
		<div className="container margin-bottom-2" style={{ width: "auto" }}>
			<div className="w-100 overflow-auto">
				<h3 className="w-50 float-left margin-top-0 margin-bottom-1 overflow-auto">Popular Artists</h3>
				<div className="w-50 overflow-auto float-right txt-right">
					<img
						style={{ height: "15px", marginRight: 10, verticalAlign: "bottom" }}
						src={logo}
						alt="Setlist Predictor Logo"
					/>
					<a className=" w-50  txt-col-primary txt-right" href="/browse/artists">
						Browse All Artists
					</a>
				</div>
			</div>
			<APICallWrapper endpoint={endpoint}>
				{({ data }: any) => (
					<div className="results-list-artists four-cols">
						{data.map((artist: any, index: number) => (
							<a
								key={artist.id}
								className="txt-decoration-none"
								href={`/setlist-prediction/${URLifyArtistName(artist.name)}/${artist.id}`}
							>
								<div key={`popular-artist-${index}`}>
									<Panel text={artist.name} />
								</div>
							</a>
						))}
					</div>
				)}
			</APICallWrapper>
		</div>
	);
}
