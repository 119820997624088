function TopSectionWrapper({ children }: any) {
	return (
		<section id="landing-section-top" className="parent-align-center">
			<div className="container">
				<div className="txt-center">{children}</div>
			</div>
		</section>
	);
}

export default TopSectionWrapper;
