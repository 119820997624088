import { useEffect, useRef, useState } from "react";

const Modal = (props: { children: any }) => {
	const firstUpdate = useRef(true);
	const [bg, setBg] = useState<any>({ background: "transparent" });

	useEffect(() => {
		if (firstUpdate.current) {
			document.body.style.overflow = "hidden";
			firstUpdate.current = false;

			setTimeout(() => {
				setBg({ background: "rgba(0,0,0,0.90)", backdropFilter: "blur(3px)", opacity: 1 });
			}, 500);
		}
	});

	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				maxHeight: "100vh",
				position: "fixed",
				top: 0,
				left: 0,
				zIndex: 99999,
				transition: "0.5s ease-in-out",
				opacity: 0,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				...bg,
			}}
		>
			<div
				className="txt-col-black txt-center"
				style={{ background: "white", padding: "1em", borderRadius: "9px" }}
			>
				<div className="padding-left-1 padding-right-1">{props.children}</div>
			</div>
		</div>
	);
};

export default Modal