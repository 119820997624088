// Components
import SearchTopSection from "./SearchTopSection";
import FeaturedSection from "../../sections/FeaturedSection/FeaturedSection";
import PopularArtists from "../../sections/FeaturedSection/PopularArtists";
// import EventsNearMe from "../../sections/FeaturedSection/EventsNearMe";
import { Helmet } from "react-helmet";

function SearchPage() {
	return (
		<>
			<Helmet>
				<meta
					name="description"
					content="Setlist Predictor helps you prepare for your next concert by providing you with an AI generated setlist prediction for an artist of your choice and an equivalent Spotify playlist so that you never miss a beat again."
				/>
				<title>Setlist Predictor - Never Miss A Beat Again</title>
			</Helmet>
			<SearchTopSection />
			<FeaturedSection>
				<PopularArtists />
				{/* <EventsNearMe /> */}
			</FeaturedSection>
		</>
	);
}

export default SearchPage;
