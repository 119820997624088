import { Artist } from "../../../types/interfaces/Artist";
import { Song } from "../../../types/interfaces/Song";
import AudioController from "./AudioController";
import styles from "./index.module.scss";

interface MusicPlayerProps {
	songs: Song[];
	setPlayingIndex: any;
	paused: boolean;
	songIndex: number;
	setPaused: any;
	artist: Artist;
}

export default function MusicPlayer(props: MusicPlayerProps) {
	const { songs, paused, songIndex, artist } = props;

	return (
		<div className={styles["music-player-container"]}>
			<div className={styles["music-player"]}>
				<AudioController
					artist={artist}
					paused={paused}
					songs={songs}
					songIndex={songIndex}
					setPlayingIndex={props.setPlayingIndex}
					setPaused={props.setPaused}
				/>
			</div>
		</div>
	);
}
