import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export const isResponsesLoaded = (res = []) =>
	res.filter((r: any) => r.loaded && !r.error).length === res.length ? true : false;

const formatResponse = (response: any) => {
	const { status, data, error, isFetching } = response;

	let errorMessage = null;
	if (status === "error") {
		errorMessage = error?.message ?? "Error";
	} else {
		if (data?.data.errorMessage) {
			errorMessage = data?.data.errorMessage;
		}
	}

	return {
		loaded: status === "loading" ? false : true,
		data: data?.data.data,
		error: errorMessage,
		isFetching,
		status,
	};
};

export const getAxiosConfig = (endpoint: string, body: any, method:string = "get", params: any) => {
	const isDev = process.env.REACT_APP_IS_DEV ? process.env.REACT_APP_IS_DEV === "true" : false;
	let requestParams = params ?? {}
	if (isDev) {
		requestParams = {...requestParams, isDev: true}
	}

	return {
		baseURL: process.env.REACT_APP_API_BASE_URL,
		url: endpoint,
		method: method,
		headers: body
			? {
					Accept: "application/json",
					"Content-Type": "application/json",
			  }
			: null,
		params: requestParams,
		data: body ? body : null,
	};
};

const useAPIQuery = (keys: any = [], config: any, staleTime: any = Infinity) => {
	const response = useQuery(keys, () => axios(config), {
		staleTime,
	});
	return formatResponse(response);
};

export const useQueryEndpoint = (endpoint: string, body: any, method: string | undefined, params: any) => {
	const config = getAxiosConfig(endpoint, body, method, params);

	return useAPIQuery([endpoint, body, params], config);
};
