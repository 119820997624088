import { Helmet } from "react-helmet";
import TopSectionWrapper from "../../sections/TopSectionWrapper/TopSectionWrapper";
import { URLifyArtistName } from "../../../helpers";
import APICallWrapper from "../../core/APICallWrapper/APICallWrapper";
import { Artist } from "../../../types/interfaces/Artist";

const endpoint = { url: "/artists/all" };
export default function BrowseArtists() {
	return (
		<>
			<Helmet>
				<title>Browse All Artists</title>
				<meta property="og:title" content={"Browse All Artists - Setlist Predictor"} />
				<meta
					name="description"
					content="View our index of all artists we have setlist data for on Setlist Predictor"
				/>
			</Helmet>
			<TopSectionWrapper>
				<h1 className="txt-weight-bold margin-top-0" style={{ fontSize: "20px" }}>
					Recently added artists:
				</h1>
				<div id="multi-box" className="transition">
					<div id="results-list-container" className="txt-left txt-col-black">
						Select an artist below to view an AI generated setlist prediction:
						<APICallWrapper endpoint={endpoint}>
							{({ data }: any) => {
								return (
									<>
										{Object.keys(data).map((key: any) => (
											<div>
												<h4 className="txt-col-primary" style={{ marginBottom: "5px" }}>
													{key.toUpperCase()}
												</h4>
												<div className="margin-bottom-1" style={{ overflow: "auto" }}>
													{data[key].map((artist: Artist) => (
														<div
															className="w-50 txt-decoration-none txt-col-black float-left pointer"
															style={{ marginBottom: "3px" }}
														>
															<a
																className="txt-decoration-none txt-col-black"
																href={`/setlist-prediction/${URLifyArtistName(
																	artist.name
																)}/${artist.id}`}
															>
																{artist.name}
															</a>
														</div>
													))}
												</div>
											</div>
										))}
									</>
								);
							}}
						</APICallWrapper>
					</div>
				</div>
			</TopSectionWrapper>
		</>
	);
}
