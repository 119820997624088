import { useLayoutEffect, useRef } from "react";

// Components
import ResultsTopSection from "./ResultsTopSection";

function ResultsPage() {
	const firstUpdate = useRef(true);

	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
	});

	return (
		<>
			<ResultsTopSection />
			{/* <FeaturedSection /> */}
		</>
	);
}

export default ResultsPage;
