import LoadingCell from "../../core/LoadingCell/LoadingCell";
import SpotifyAuth from "../../core/SpotifyAuth/SpotifyAuth";

export default function CallbackSpotify() {
	return (
		<>
			<div id="multi-box" className="transition">
				<div id="results-list-container">
					<LoadingCell loadingTerm={["Authenticating"]} />
				</div>
			</div>
			<SpotifyAuth />
		</>
	);
}
